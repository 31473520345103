
import Layout from "hocs/layouts/Layout"
import { connect } from "react-redux";
import React, { useState } from 'react';
import Navbar from "components/navigation/Navbar";
import asterisco from 'assets/img/asterisco.png';
import slash from 'assets/img/slash.png';
import flecha from 'assets/img/f-blanca.png';
import phoneImage from 'assets/img/celular.png';
import Header from "components/home/Header";
import CardPhone from "components/home/CardPhone";
import TutorialSection from "components/home/TutorialSection";
import Footer from "components/navigation/Footer";
import Associations from "components/home/Associations";


function Home({

}) {





    return (
        <Layout>
            <Navbar />
            <Header />

            <div className="container-flex-head-2 hide-on-mobile">
                <div className="image-container">
                    <img src={phoneImage} alt="Eazt App Showcase" className="img-fluid" />
                </div>
            </div>

            <div className="container-flex-head-3">
                <div className="container d-flex justify-content-center align-items-center" >

                    <div className="row ">
                        <div className="col-md-4 ">
                            <CardPhone
                                name={"Conexión Sabrosa"}
                                description={"Te conectamos con opciones rápidas y confiables que satisfacen tus antojos."}
                                icon={flecha}
                                


                            ></CardPhone>
                        </div>
                        <div className="col-md-4">
                            <CardPhone
                                name={"Comida real, valoraciones reales"}
                                description={"Conectamos a los comensales con restaurantes de calidad. Nuestra IA elimina el ruido del marketing y te revela la verdad."}
                                icon={asterisco}


                            ></CardPhone>
                        </div>
                        <div className="col-md-4">
                            <CardPhone
                                name={"Calidad sin perder tiempo"}
                                description={"Tu búsqueda es sencilla. Encuentra restaurantes auténticos y obtén evaluaciones rápidas con IA."}
                                icon={slash}


                            >

                            </CardPhone>
                        </div>


                    </div>
                </div>


            </div>
            {/*            <div className="container-flex-head-2 pt-5">
                <div className="container">
                    <div className="row mt-3">
                        <TutorialSection></TutorialSection>


                    </div>
                </div>
            </div>*/ }


            <Associations></Associations>




            <Footer></Footer>
        </Layout>
    )
}
const mapStateToProps = state => ({






})

export default connect(mapStateToProps, {


})(Home)