import React from 'react';
import jean from "assets/img/jean assoc.png";
import felipe from "assets/img/felipe.png";

const Associations = () => {
  return (
    <div className="container-flex-head pb-5 ">


      <div className='container '>
        <h2 className='text-white text-center'> Nuestros Aliados</h2>

        <div className='row mt-5'>
          <div className='col-md-3'>


          </div>
          <div className='col-md-3'>
          <a href='https://www.instagram.com/felipeutima_07/'>
              <img className='img-fluid  hover-scale' src={felipe}></img>
            </a>
          </div>
          <div className='col-md-3'>
            <a href='https://www.instagram.com/design_jean/'>
              <img className='img-fluid p-4 hover-scale' src={jean}></img>
            </a>

          </div>

          <div className='col-md-3'>


          </div>


        </div>
      </div>

    </div>
  );
};

export default Associations;
