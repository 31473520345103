import { connect } from "react-redux"
import burguer from 'assets/img/burguer.jpg';  // Cambia la ruta a donde está tu imagen
import dog from 'assets/img/dog.jpg';  // Cambia la ruta a donde está tu imagen
import { motion } from "framer-motion";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from "react";
import eaztBlanco from 'assets/img/eazt blanco.png';


function Header() {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(window.innerWidth < 768);
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <div className="container-flex-head">
                <div className="row">
                    <div className="col-md-4">
                        <motion.div
                            className={` hide-on-mobile ${isSmallScreen ? "mb-5" : ""}`}
                            initial={{ y: 0 }} /* Posición inicial */
                            animate={{ y: [0, -15, 0] }} /* Movimientos hacia arriba y luego hacia abajo */
                            transition={{
                                duration: 3, /* Duración total de la animación */
                                ease: "easeInOut", /* Movimiento suave */
                                repeat: Infinity, /* Repetir indefinidamente */
                                repeatType: "mirror", /* Repite de manera continua */
                            }}
                        >
                            <img src={dog} alt="Imagen izquierda" className="img-fluid image-left" />
                        </motion.div>



                    </div>
                    <div className="col-md-4 ">


                        <motion.div initial={{ y: -70, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.1, duration: 1.5 }} viewport={{ once: true }}  >
                            {isSmallScreen ? <>

                                <img className="img fluid mb-5" src={eaztBlanco}></img>
                                <h1 style={{ fontSize: "40px" }}>Saborea la honestidad en cada recomendación</h1>

                            </>
                                : 
                                <h1 style={{ fontSize: "40px" }}>Saborea la honestidad en cada recomendación</h1>
                            }
                        </motion.div>

                        <motion.div initial={{ y: -70, opacity: 0 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.5, duration: 1.5 }} viewport={{ once: true }}  >
                            <p style={{ fontSize: "20px" }}>Nuestra IA analiza y destaca los mejores restaurantes de calidad cerca de ti, sin perder tiempo en publicidad engañosa. </p>
                        </motion.div>




                        <a href='/login' style={{ textDecoration: "none" }}>
                            <button class="cssbuttons-io-button mx-auto px-3 py-3">


                                Empezar

                            </button>
                        </a>


                    </div>
                    <div className="col-md-4">
                        <motion.div
                            className={` ${isSmallScreen ? "mt-5" : ""}`}

                            initial={{ y: 0 }} /* Posición inicial */
                            animate={{ y: [0, -10, 0] }} /* Movimientos hacia arriba y luego hacia abajo */
                            transition={{
                                duration: 3, /* Duración total de la animación */
                                ease: "easeInOut", /* Movimiento suave */
                                repeat: Infinity, /* Repetir indefinidamente */
                                repeatType: "mirror", /* Repite de manera continua */

                            }}

                        >
                            <img src={burguer} alt="Imagen derecha" className="img-fluid image-right" />
                        </motion.div>



                    </div>



                </div>
            </div>


        </>

    );
}

const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Header)