import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import eazt from "assets/img/logo largo.png";
import eaztFlecha from "assets/img/flecha.png";
import { PersonCircle } from 'react-bootstrap-icons';
import { logout } from 'redux/actions/auth/auth'
import { useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom'
const NavbarAppPhone = ({ logout, user }) => {




  const navigate = useNavigate()

  const handleLogout = () => {
    logout()
    navigate('/login')
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="custom-navbar py-3">
        <Container>

          {/* Logo a la izquierda */}
          <a href='/'>
            <img
              src={eaztFlecha}
              width="40px"
              className="d-inline-block align-top img-fluid"
              alt="Eazt Logo"
              style={{marginLeft:"15px"}}
            />
          </a>
                <img
                  src={eazt}
                  width="100px"
                  className=" mx-auto img-fluid"
                  alt="Eazt Logo"
                  style={{marginLeft:"40px"}}
                />
            <div className="dropdown cssbuttons-io-button px-3 py-2">
              <button className="dropdown-toggle" id="dropdown-basic" aria-haspopup="true" aria-expanded="false" onClick={toggleDropdown}>
                <PersonCircle color='#fff' size={30} className='mx-1' />
                {  /* user ? user.first_name : 'loading' */}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdown-basic">
                <a className="dropdown-item" href="/favorites">Favoritos</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" onClick={() => handleLogout()}>Log Out</a>
              </div>
            </div>

       
        </Container>
      </Navbar>

    </>
  );
};
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {
  logout

})(NavbarAppPhone)  