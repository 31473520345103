
import Layout from "hocs/layouts/Layout"

import { useEffect } from 'react'
import { connect } from "react-redux";
import React, { useState } from 'react';
import Navbar from "components/navigation/Navbar";
import { register } from "redux/actions/auth/auth";

import Footer from "components/navigation/Footer";
import eaztBrand from "assets/img/eazt logo.png";
import BounceLoader from "react-spinners/HashLoader"
function Register({

    register
}) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);

    const [accountCreated, setAccountCreated] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const validatePassword = (password) => {
        const minLength = 8;
        const hasNumber = /\d/; // Al menos un número
        const hasUpperCase = /[A-Z]/; // Al menos una letra mayúscula
        const hasLowerCase = /[a-z]/; // Al menos una letra minúscula

        if (password.length < minLength) {
            return `La contraseña debe tener al menos ${minLength} caracteres.`;
        }
        if (!hasNumber.test(password)) {
            return 'La contraseña debe contener al menos un número.';
        }
        if (!hasUpperCase.test(password)) {
            return 'La contraseña debe contener al menos una letra mayúscula.';
        }
        if (!hasLowerCase.test(password)) {
            return 'La contraseña debe contener al menos una letra minúscula.';
        }
        return '';
    };
    const onPasswordChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, password: value });

        const error = validatePassword(value);
        setPasswordError(error);
    };


    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        re_password: ''
    })

    const {
        first_name,
        last_name,
        email,
        password,
        re_password
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });



    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);


        const error = validatePassword(password);
        if (error) {
            setPasswordError(error);
            return;
        }

        if (password !== re_password) {
            setPasswordError('Las contraseñas no coinciden.');
            return;
        }

        try {
            const response = await register(first_name, last_name, email, password, re_password);

            if (response) {
                console.log('Respuesta del servidor:', response);
                setIsRegistrationSuccessful(true);
                setAccountCreated(true);
                window.scrollTo(0, 0);
            } else {
                setPasswordError('Asegurate que la contraseña no sea similar al nombre o correo.');
            }
        } catch (error) {
            if (error && error.status === 400) {
                console.error('Error 400 (Bad Request):', error);
            } else {
                console.error('Error general:', error.message || error);
            }
        }
        setLoading(false);
    };


    return (
        <Layout>
            <Navbar />

            <div class="container-log mx-auto  my-5">

                {isRegistrationSuccessful ? (
                    <div class="mx-auto text-center">
                        <div className="success-message mx-auto my-5">
                            <img src={eaztBrand} className="img-fluid mx-auto"></img>
                            <h1>¡Registro exitoso!</h1>
                            <p>Gracias por registrarte. Ahora puedes iniciar sesión.</p>
                            <a href="login" style={{ textDecoration: "none" }}>
                                <button class="sign-in_btn" type="submit" title="Sign In">
                                    <span>Ir al Login</span>
                                </button>
                            </a>
                        </div>
                    </div>
                ) : (
                    <div class="login-form mx-auto my-5">
                        <div class="header">
                            <label class="title">Registrar</label>
                            <p class="description mt-5">Registrate en Eazt ingresando los siguientes datos.</p>
                        </div>
                        <form onSubmit={e => { onSubmit(e) }} className="form_main mx-auto  " action="">

                            <div className="container">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div class="input_container my-1 ">

                                            <input placeholder="Nombre"
                                                id="first_name" name='first_name'
                                                className="input_field ps-2"
                                                type="text"
                                                required
                                                value={first_name}
                                                onChange={e => onChange(e)} />
                                        </div>
                                    </div>

                                    <div className="col-md-12">

                                        <div class="input_container my-1 ">

                                            <input placeholder="Apellido"
                                                id="last_name" name='last_name'
                                                className="input_field ps-2"
                                                type="last_name"
                                                required
                                                value={last_name}
                                                onChange={e => onChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">

                                        <div class="input_container my-1 ">

                                            <input placeholder="Email"
                                                id="email" name='email'
                                                className="input_field ps-2"
                                                type="email"
                                                required
                                                value={email}
                                                onChange={e => onChange(e)} />
                                        </div>
                                    </div>

                                    <div class="input_container my-1 col-md-12">

                                        <input placeholder="Password"
                                            id="password"
                                            className="input_field ps-2"
                                            type="password"
                                            name='password'
                                            value={password} required
                                            onChange={onPasswordChange}
                                        />
                                    </div>
                                    <div class="input_container my-1 col-md-12">

                                        <input placeholder="re_password"
                                            id="re_password"
                                            className="input_field ps-2"
                                            type="password"
                                            name='re_password'
                                            value={re_password} required
                                            onChange={e => onChange(e)} />
                                        {passwordError && <p className="alert alert-danger my-2">{passwordError}</p>}
                                    </div>
                                </div>
                            </div>


                            {loading ?
                                <div className="d-flex justify-content-center mx-auto mt-2  ">
                                    <BounceLoader color="#ff1423" size={50} />


                                </div>
                                :

                                <>
                                    <button class="sign-in_btn" type="submit" title="Sign In">
                                        <span>Registrarme</span>
                                    </button>
                                </>
                            }

                        </form>
                    </div>
                )}
            </div>
            <Footer></Footer>


        </Layout>
    )
}
const mapStateToProps = state => ({


})
export default connect(mapStateToProps, {

    register

})(Register)